import React from 'react';
import './cssberhasil';
import LoadingLogo from '../../upload/loading_logo.png';
import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'gatsby';
import Banner from '../content/404/Banner';
import Helmet from 'react-helmet';
import JSberhasil from './jsberhasil';
import Mobile from '../content/Mobile';

const Layout404 = () => {
	return (
		// <>
		<React.Fragment>
			<Helmet
				bodyAttributes={{
					class:
						'home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074'
				}}
			/>

			{/*
			<div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
			</div>
			*/}

			<div id="perspective">
				<Mobile />
				<div id="wrapper" className="hasbg transparent" style={{ paddingTop: '0px' }}>
					<div id="elementor-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="3141"
							className="elementor elementor-3141"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Header />
									<Navbar />
								</div>
							</div>
						</div>
					</div>

					<div id="page-content-wrapper" className="">
						<div className="inner">
							<div className="inner-wrapper">
								<div className="sidebar-content fullwidth">
									<div
										data-elementor-type="wp-page"
										data-elementor-id="5459"
										className="elementor elementor-5459"
										data-elementor-settings="[]"
									>
										<div className="elementor-inner">
											<div className="elementor-section-wrap">
												{' '}
												<header className="error">
													<Banner title="PAGE NOT FOUND" />
													{/* <testComponent imgPassing="../../upload/loading_logo.png" /> */}
												</header>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<JSberhasil />
			{/* </> */}
		</React.Fragment>
	);
};

export default Layout404;
